import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './Header';
import Footer from './Footer';
import './MainPage.css'; // Import the CSS file for custom styles

const MainPage = () => {
    const [documents, setDocuments] = useState([]);
    const [folders, setFolders] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState('');
    const [error, setError] = useState('');

    const sheetId = process.env.REACT_APP_SHEET_ID;
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

    useEffect(() => {
        const fetchData = async () => {
            const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}?key=${apiKey}`;

            try {
                const response = await axios.get(url);
                const sheets = response.data.sheets;
                const folderNames = sheets.map(sheet => sheet.properties.title);
                setFolders(folderNames);

                // Automatically select and fetch documents for the first folder
                if (folderNames.length > 0) {
                    setSelectedFolder(folderNames[0]);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Failed to fetch folder data.');
            }
        };

        fetchData();
    }, [sheetId, apiKey]);

    useEffect(() => {
        if (selectedFolder) {
            const fetchDocuments = async () => {
                const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${selectedFolder}?key=${apiKey}`;

                try {
                    const response = await axios.get(url);
                    const rows = response.data.values;
                    if (rows) {
                        const docs = rows.slice(1).map(row => ({
                            fileName: row[0],
                            name: row[1],
                            fileSize: row[2],
                            downloadLink: `https://drive.google.com/uc?export=download&id=${row[3].split('/d/')[1].split('/')[0]}`
                        }));
                        setDocuments(docs);
                    } else {
                        setError('No data found for the selected folder.');
                    }
                } catch (error) {
                    console.error('Error fetching documents:', error);
                    setError('Failed to fetch documents.');
                }
            };

            fetchDocuments();
        }
    }, [selectedFolder, sheetId, apiKey]);

    return (
        <div>
            <Header username="client" />
            <div className="container mt-5">
                <h2 className="mb-4">Documents</h2>
                {error && <div className="alert alert-danger">{error}</div>}
                <div className="folders-container mb-4">
                    {folders.map((folder, index) => (
                        <div 
                            key={index} 
                            className="folder" 
                            onClick={() => setSelectedFolder(folder)}
                        >
                            <div className="folder-icon">
                                {selectedFolder === folder ? '📂' : '📁'}
                            </div>
                            <div className="folder-name">{folder}</div>
                        </div>
                    ))}
                </div>
                {selectedFolder && (
                    <table className="table table-dark table-striped">
                        <thead>
                            <tr>
                                <th scope="col">File Name</th>
                                <th scope="col">Name</th>
                                <th scope="col">File Size (MB)</th>
                                <th scope="col">Download</th>
                            </tr>
                        </thead>
                        <tbody>
                            {documents.map((doc, index) => (
                                <tr key={index}>
                                    <td>{doc.fileName}</td>
                                    <td>{doc.name}</td>
                                    <td>{doc.fileSize}</td>
                                    <td>
                                        <a href={doc.downloadLink} className="btn btn-primary" target="_blank" rel="noopener noreferrer">
                                            Download
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default MainPage;
