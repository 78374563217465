import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './LoginPage.css'; // Import CSS for specific styles

const LoginPage = () => {
    const [email, setEmail] = useState(''); // Initial state is an empty string
    const [password, setPassword] = useState(''); // Initial state is an empty string
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = () => {
        if (email === 'client' && password === 'client') {
            navigate('/main');
        } else {
            setError('Invalid username or password');
        }
    };

    return (
        <div className="container d-flex flex-column align-items-center justify-content-center min-vh-100">
            <img src="/images/sirrihatt_logo_full.png" alt="Sirrihatt Logo" className="mb-4" style={{ width: '200px' }} />
            <h3 className="mb-4 text-center">Sirrihatt Document Cloud</h3>
            <div className="card p-4 text-white">
                <div className="card-body">
                    <h4 className="mb-4 text-left">Login</h4>
                    {error && <div className="alert alert-danger">{error}</div>}
                    <input
                        type="text"
                        className="form-control mb-2"
                        placeholder="Username/Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        type="password"
                        className="form-control mb-2"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button className="btn btn-primary w-100" onClick={handleLogin}>
                        Login
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
