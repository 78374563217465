import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import MainPage from './components/MainPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'; // Import the global CSS for dark mode

const App = () => {
    return (
        <Router>
            <div id="root">
                <Routes>
                    <Route path="/" element={<LoginPage />} />
                    <Route path="/main" element={<MainPage />} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;
