import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Footer.css'; // Import CSS for specific styles

const Footer = () => {
    return (
        <footer className="text-center p-3">
            2024 Sirrihatt Subsea Services Sdn Bhd.
        </footer>
    );
};

export default Footer;
