import React from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css'; // Import CSS for specific styles

const Header = ({ username }) => {
    const navigate = useNavigate();

    const handleLogout = () => {
        // Perform logout logic
        navigate('/');
    };

    return (
        <header className="d-flex justify-content-between align-items-center p-3" style={{ backgroundColor: '#1f2833', height: '60px' }}>
            <div className="d-flex align-items-center">
                <img src="/images/sirrihatt_logo_full.png" alt="Sirrihatt Logo" style={{ height: '40px' }} />
                <h4 className="ms-3 mt-3 text-white">Sirrihatt Document Cloud</h4>
            </div>
            <div>
                <span className="me-3 text-white">Hello, {username}</span>
                <button className="btn btn-link p-0 text-white" onClick={handleLogout}>
                    Logout <i className="bi bi-box-arrow-right"></i>
                </button>
            </div>
        </header>
    );
};

export default Header;
